import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import { NgbDateStruct, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

import { config } from './config.service';
import { ApiResponse } from './api-response';


import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(
    private toastrService: ToastrService,
    private ngbDateParserFormatter: NgbDateParserFormatter,
  ) { }

  public checkSuccess(response: any): Promise<any> {
    if(response.success) return Promise.resolve(response);
    else return Promise.reject(response);
  }

  public handleApiError(error: any): Promise<any> {
    if(!config.production) console.error('An error occurred', error);
    return Promise.reject(error);
  }

  public updateFormErrors(form: FormGroup, formErrors: any) {
    if (!form) { return; }

    for( const field in form.controls ) {
      const control = form.get( field );

      formErrors[field] = '';

      if (control && control.dirty && !control.valid) {
        for (const key in control.errors) {
          let message = '';

          if( key == 'ngbDate' ) {
          } else {
            switch( key ) {
              case 'required':
                message = '필수 입력(선택) 항목입니다.';
                break;
              case 'email':
                message = '이메일 형식으로 입력하세요.';
                break;
              case 'maxlength':
                message = '최대 ' + control.errors[key].requiredLength + '자 까지 입력 가능합니다.';
                break;
              case 'minlength':
                message = '최소 ' + control.errors[key].requiredLength + '자 이상 입력 하세요.';
                break;
              case 'pattern':
                message = '입력 형식이 올바르지 않습니다.';
                break;
              case 'numeric':
                message = '숫자만 입력해 주시기 바랍니다.';
                break;
              case 'alpha':
                message = '영문만 입력해 주시기 바랍니다.';
                break;
              case 'alphaUpper':
                message = '영문 대문자만 입력해 주시기 바랍니다.';
                break;
              case 'alphaLower':
                message = '영문 소문자만 입력해 주시기 바랍니다.';
                break;
              case 'phone':
                message = '숫자와 -만 입력해 주시기 바랍니다.';
                break;
              case 'ngbDate':
                message = '날짜를 선택해 주시기 바랍니다.';
                break;
            }

            formErrors[field] = message;
          }
        }
      }
    }
  }

  public updateFieldErrors(form: FormGroup, formErrors: any, Field: any) {
    if (!form) { return; }

    for( const field in form.controls ) {
      if( Field == field ) {
        const control = form.get( field );

        formErrors[field] = '';

        if (control && control.dirty && !control.valid) {
          for (const key in control.errors) {
            let message = '';

            if( key == 'ngbDate' ) {
            } else {
              switch( key ) {
                case 'required':
                  message = '필수 입력(선택) 항목입니다.';
                  break;
                case 'email':
                  message = '이메일 형식으로 입력하세요.';
                  break;
                case 'maxlength':
                  message = '최대 ' + control.errors[key].requiredLength + '자 까지 입력 가능합니다.';
                  break;
                case 'minlength':
                  message = '최소 ' + control.errors[key].requiredLength + '자 이상 입력 하세요.';
                  break;
                case 'pattern':
                  message = '입력 형식이 올바르지 않습니다.';
                  break;
                case 'numeric':
                  message = '숫자만 입력해 주시기 바랍니다.';
                  break;
                case 'alpha':
                  message = '영문만 입력해 주시기 바랍니다.';
                  break;
                case 'alphaUpper':
                  message = '영문 대문자만 입력해 주시기 바랍니다.';
                  break;
                case 'alphaLower':
                  message = '영문 소문자만 입력해 주시기 바랍니다.';
                  break;
                case 'phone':
                  message = '숫자와 -만 입력해 주시기 바랍니다.';
                  break;
              }

              formErrors[field] = message;
            }
          }
        }
      }
    }
  }

  public makeAllFormFieldsDirty(form: FormGroup) {
    if (!form) { return; }

    for (var field in form.controls) {
      const control = form.get(field);
      if(control) control.markAsDirty();
    }
  }

  public makeFieldsDirty(form: FormGroup, Field: any) {
    if (!form) { return; }

    for(var field in form.controls) {
      if( field == Field ) {
        const control = form.get(field);
        if(control) control.markAsDirty();
      }
    }
  }

  public makeFormDirtyAndUpdateErrors(form: FormGroup, formErrors: any) {
    this.makeAllFormFieldsDirty(form);
    this.updateFormErrors(form, formErrors);
  }

  public makeFieldDirtyAndUpdateErrors(form: FormGroup, formErrors: any, field: any) {
    this.makeFieldsDirty(form, field);
    this.updateFieldErrors(form, formErrors, field);
  }

  public handleFormSubmitError(response: ApiResponse, form: FormGroup, formErrors: any): void {
    if(response.errors){
      for (const field in formErrors) {
        const control = form.get(field);
        if (response.errors[field] && response.errors[field].message) {
          formErrors[field] += response.errors[field].message;
        }
      }
      if(response.errors.unhandled){
        response.message += response.errors.unhandled;
      }
    }
  }

  /*******************************************************************************
    설  명 : ngbDateStruct
    입력값 : '' or date string
    리턴값 : ngbDateStruct array
  *******************************************************************************/
  getDate( date: any ): any {
    let tmp: NgbDateStruct

    if( date == '' ) {
      tmp = {
        year: parseInt( moment().format('YYYY') ),
        month: parseInt( moment().format('MM') ),
        day: parseInt( moment().format('DD') )
      };
    } else {
      tmp = {
        year: parseInt( moment(date).format('YYYY') ),
        month: parseInt( moment(date).format('MM') ) - 1,
        day: parseInt( moment(date).format('DD') )
      };
    }

    return tmp;
  }

  /*******************************************************************************
    설  명 : ngbDateStruct
    입력값 : '' or date string
    리턴값 : ngbDateStruct array
  *******************************************************************************/
  getMonthFirstLast( date: any, type ): any {
    let tmp: NgbDateStruct;
    let startOfDate: any;
    let endOfDate: any;

    if( date == '' ) {
      startOfDate = moment().startOf('month').format('YYYY-MM-DD').split('-');
      endOfDate = moment().endOf('month').format('YYYY-MM-DD').split('-');
    } else {
      startOfDate = moment( moment(date).subtract(1, 'months') ).startOf('month').format('YYYY-MM-DD').split('-');
      endOfDate = moment( moment(date).subtract(1, 'months') ).endOf('month').format('YYYY-MM-DD').split('-');
    }

    if( type == 'first' ) {
      tmp = {
        year: parseInt( startOfDate[0] ),
        month: parseInt( startOfDate[1] ),
        day: parseInt( startOfDate[2] )
      };

      return tmp;
    } else {
      tmp = {
        year: parseInt( endOfDate[0] ),
        month: parseInt( endOfDate[1] ),
        day: parseInt( endOfDate[2] )
      };

      return tmp;
    }
  }

  /*******************************************************************************
    설  명 : ngbDateStruct
    입력값 : '' or date string
    리턴값 : ngbDateStruct array
  *******************************************************************************/
  getDateStr( date: NgbDateStruct ): string {
    return this.ngbDateParserFormatter.format( date );
  }

  /*******************************************************************************
    설  명 : 콤마 표시
    입력값 : 숫자
    리턴값 : 콤마 숫자
  *******************************************************************************/
  getComma( num ) {
    if( num == '' || num == null ) return 0;

    var str = String(num);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  }
}