import { async } from '@angular/core/testing';
import { Injectable, NgModuleFactoryLoader } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { Globals } from '@app/service/globals.service';
import { UtilService } from '@app/service/util.service';

import { HAuthService } from '@home/service/auth.service';
import { HMemberService } from '@home/service/member.service';

@Injectable()
export class AuthLoginCheck implements CanActivate {

  public redirectURL: string = '';

  constructor(
    private router: Router,
    private globals: Globals,
    private memberService: HMemberService,
    private toastrService: ToastrService,
    private readonly loader: NgModuleFactoryLoader,
    private authService: HAuthService,
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    this.redirectURL = state.url;

    return this.memberService.getMemberInfo().then( response => {
      if( response.ResultCode == true ) {
        this.authService.login( response );

        if ( !response.data ) {
          this.memberService.getMemberDormant().then( result => {
            // 휴면회원 처리
            if (result.data) {
              this.router.navigate(['/member/dormant']);
              return false;
            }
          });
        }

      } else {
        this.memberService.setLogout().then( response => {
          if( response.ResultCode == true ) {
            this.authService.logout();
          }
        });
      }

      return true;
    })
    .catch(response => {
      return false;
    });
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.canActivate(next, state);
  }
}
