import { async } from '@angular/core/testing';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

import { RestfulService } from '@app/service/restful.service';

@Injectable({
  providedIn: 'root'
})

export class HMemberService {

  public memberInfo: any;

  constructor(
    private restful: RestfulService
  ) {
  }

  private extractData(res: Response) {
    let body = res;
    return body || [ ];
  }

  private handleErrorPromise(error: Response | any) {
    console.log(error.message || error);
    return Promise.reject(error.message || error);
  }

  // 로그인정보 가져오기
  getMemberInfo(): Promise<any> {
    return this.restful.get({
        program: 'home',
        service: 'member',
        version: '1.0',
        action: 'getMember'
      }, {
      }
    ).then( response => {
      if ( response.ResultCode ) {
        try {
          this.memberInfo = response.data[0];
        } catch ( err) {
          this.memberInfo = [];
        }
      } else {
        this.memberInfo = [];
      }

      return response || [];
    })
     .catch(this.handleErrorPromise);
  }

  // 휴면회원 로그인정보 가져오기
  getMemberDormant(): Promise<any> {
    return this.restful.get({
        program: 'home',
        service: 'member',
        version: '1.0',
        action: 'getMemberDormant'
      }, {}
    ).then( response => {
      return response || [];
    })
     .catch(this.handleErrorPromise);
  }

  // 로그인
  setLogin( form: any ): Promise<any> {
    return this.restful.get({
        program: 'home',
        service: 'member',
        version: '1.0',
        action: 'setLogin'
      }, form.value
    ).then( response => {
      return response || [];
    })
     .catch(this.handleErrorPromise);
  }

  // 로그아웃
  setLogout(): Promise<any> {
    return this.restful.get({
        program: 'home',
        service: 'member',
        version: '1.0',
        action: 'setLogout'
      }, {}
    ).then( response => {
      return response || [];
    })
     .catch(this.handleErrorPromise);
  }

  // 아이디 중복체크
  checkIdDuplicate( id: any ): Promise<any> {
    return this.restful.get({
        program: 'home',
        service: 'member',
        version: '1.0',
        action: 'checkIdDuplicate'
      }, {
        userId: id
      }
    ).then( response => {
      return response || [];
    })
     .catch(this.handleErrorPromise);
  }

  // 이메일 중복체크
  checkEmailDuplicate( email: any ): Promise<any> {
    return this.restful.get({
        program: 'home',
        service: 'member',
        version: '1.0',
        action: 'checkEmailDuplicate'
      }, {
        userEmail: email
      }
    ).then( response => {
      return response || [];
    })
     .catch(this.handleErrorPromise);
  }

  // 이메일 중복체크(회원정보수정)
  checkEmailChangeDuplicate( email: any ): Promise<any> {
    return this.restful.get({
        program: 'home',
        service: 'member',
        version: '1.0',
        action: 'checkEmailChangeDuplicate'
      }, {
        userEmail: email
      }
    ).then( response => {
      return response || [];
    })
     .catch(this.handleErrorPromise);
  }

  // 기업 리스트 가져오기
  getCompanySearchList( search: any ): Promise<any> {
    return this.restful.get({
        program: 'home',
        service: 'member',
        version: '1.0',
        action: 'getCompanySearchList'
      }, search
    ).then( response => {
      return response || [];
    })
     .catch(this.handleErrorPromise);
  }

  // 기업회원 리스트 가져오기
  getCompanyMember( companySeq: any ): Promise<any> {
    return this.restful.get({
        program: 'home',
        service: 'member',
        version: '1.0',
        action: 'getCompanyMember'
      }, {seq: companySeq}
    ).then( response => {
      return response || [];
    })
     .catch(this.handleErrorPromise);
  }

  // 기업 정보확인
  checkCompanyNumber( seq: any, compayNumber: any ): Promise<any> {
    return this.restful.get({
        program: 'home',
        service: 'member',
        version: '1.0',
        action: 'checkCompanyNumber'
      }, {
        companySeq: seq,
        comNumber: compayNumber
      }
    ).then( response => {
      return response || [];
    })
     .catch(this.handleErrorPromise);
  }

  // 회원가입
  setMemberSave( form: any ): Promise<any> {
    return this.restful.post({
        program: 'home',
        service: 'member',
        version: '1.0',
        action: 'setMemberSave'
      }, form.value
    ).then( response => {
      return response || [];
    })
     .catch(this.handleErrorPromise);
  }

  // 아이디 찾기
  setFindId( form: any ): Promise<any> {
    return this.restful.get({
        program: 'home',
        service: 'member',
        version: '1.0',
        action: 'setFindId'
      }, form.value
    ).then( response => {
      return response || [];
    })
     .catch(this.handleErrorPromise);
  }

  // 비밀번호 찾기
  setFindPw( form: any ): Promise<any> {
    return this.restful.get({
        program: 'home',
        service: 'member',
        version: '1.0',
        action: 'setFindPw'
      }, form.value
    ).then( response => {
      return response || [];
    })
     .catch(this.handleErrorPromise);
  }

  // 비밀번호 변경
  setChangePassword( form: any ): Promise<any> {
    return this.restful.post({
        program: 'home',
        service: 'member',
        version: '1.0',
        action: 'changePassword'
      }, form.value
    ).then( response => {
      return response || [];
    })
     .catch(this.handleErrorPromise);
  }

  // 인증키로 회원정보 찾기
  setFindDikey( dikey: any ): Promise<any> {
    return this.restful.get({
        program: 'home',
        service: 'member',
        version: '1.0',
        action: 'setFindDikey'
      }, {
        dupinfo: dikey
      }
    ).then( response => {
      return response || [];
    })
      .catch(this.handleErrorPromise);
  }

  // 회원정보 가져오기
  getMemberDetail(): Promise<any> {
    return this.restful.get({
        program: 'home',
        service: 'member',
        version: '1.0',
        action: 'getMemberDetail'
      }, {}
    ).then( response => {
      return response || [];
    })
      .catch(this.handleErrorPromise);
  }

  // 회원탈퇴
  setMeberOut( form: any ): Promise<any> {
    return this.restful.get({
        program: 'home',
        service: 'member',
        version: '1.0',
        action: 'setMeberOut'
      }, form.value
    ).then( response => {
      return response || [];
    })
      .catch(this.handleErrorPromise);
  }

  // 무통장 입금 신청
  setPaymentBank(): Promise<any> {
    return this.restful.get({
        program: 'home',
        service: 'member',
        version: '1.0',
        action: 'setPaymentBank'
      }, {}
    ).then( response => {
      return response || [];
    })
      .catch(this.handleErrorPromise);
  }

  // 결제정보 리스트 가져오기
  getMemberPaymentList( memNo ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'getMemberPaymentList'
      }, {
        memberNo: memNo
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 기업 담당자 승인처리
  setCompanyPersonApproval( data: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'setCompanyPersonApproval'
      }, {
        params: data
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 기업 담당자 승인취소 처리
  setCompanyPersonApprovalCancel( data: any ): Promise<any> {
    return this.restful.post({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'setCompanyPersonApprovalCancel'
      }, {
        params: data
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 기업회원 주담당자 지정 세팅 처리.
  setCompanyMajor( companySeq, memNo ): Promise<any> {
    return this.restful.get({
        program: 'admin',
        service: 'member',
        version: '1.0',
        action: 'setCompanyMajor'
      }, {
        company_seq: companySeq,
        member_seq: memNo
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }

  // 휴면회원 해제
  setActiveMember(): Promise<any> {
    return this.restful.get({
        program: 'home',
        service: 'member',
        version: '1.0',
        action: 'setActiveMember'
      }, {
      }
    ).then(this.extractData)
     .catch(this.handleErrorPromise);
  }
}
