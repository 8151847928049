const baseURL = window.location.protocol + '//www.ref.or.kr';

export const config = {
  production: false,
  baseUrl: baseURL,
  apiBaseUrl: baseURL + "/api",
  apiFileUploadUrl: baseURL + "/api/file/file_upload.php",
  apiImageUploadUrl: baseURL + "/api/file/image_upload.php",
  cookie_token: 'reftoken',
  cookie_id: 'refid'
};
