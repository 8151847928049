<router-outlet></router-outlet>

<!--
<ng-container *ngIf="checkAdminPage">
  <div class="admin-body" *ngIf="! checkPrintPage">
    <app-aheader *ngIf="checkAdminLoginPage===true"></app-aheader>
    <app-login-header *ngIf="checkAdminLoginPage===false"></app-login-header>
    <app-agnb *ngIf="checkAdminLoginPage===true"></app-agnb>
    <router-outlet></router-outlet>
  </div>
  <div class="print-body" *ngIf="checkAdminLoginPage===true && checkPrintPage">
    <router-outlet></router-outlet>
  </div>

</ng-container>

<ng-container *ngIf="!checkAdminPage && isStatisticsPage">
  <router-outlet></router-outlet>
</ng-container>

<ng-container *ngIf="!checkAdminPage && isWebzinePage">
  <app-webzine-header></app-webzine-header>

  <div class="webzine-wrap">
    <app-webzine-lnb></app-webzine-lnb>
    <router-outlet></router-outlet>
  </div>

  <app-footer></app-footer>
</ng-container>
//-->
<!--ng-container *ngIf="!checkAdminPage && !isStatisticsPage && !isWebzinePage">
  <div class="wrap">
    <app-header></app-header>
    <router-outlet></router-outlet>
    <app-footer></app-footer>
  </div>
</ng-container//-->