import { Component } from '@angular/core';
import {
  Router, Routes, RouterModule,
  Event, ActivatedRoute,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError
} from '@angular/router';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';

import { Globals } from '@app/service/globals.service';

import { AuthService } from '@admin/service/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  /*******************************************************************************
    설명 : 전역 변수 선언부
  *******************************************************************************/
  private subscription: Subscription = new Subscription;
  private subscription1: Subscription = new Subscription;

  public title = 'REF';
  public isStatisticsPage: boolean = false;  //통계 페이지 체크
  public isWebzinePage: boolean = false;  //WebZine 페이지 체크
  public checkAdminPage: boolean = false;
  public checkAdminLoginPage: boolean = false;    // 로그인 여부
  public checkHomeLoginPage: boolean = false;    // 로그인 여부
  public checkPrintPage:boolean = false;    // 출력 페이지 여부

  /*******************************************************************************
    설  명 : 생성자
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  constructor(
    private authService: AuthService,
    private location: Location,
    public globals: Globals,
    private router: Router,
  ) {

  }

  /*******************************************************************************
    설  명 : 데이터 초기화
    입력값 : 없음
    리턴값 : 없음
  *******************************************************************************/
  public ngOnInit() {
    // 로그인 여부 체크
    this.subscription = this.authService.getIsLogin.subscribe( check => {
      this.checkAdminLoginPage = check;
      console.log( check );
    });

    if( this.location.path().indexOf( '/admin' ) > -1 ) {
      this.checkAdminPage = true;

      if( this.location.path().indexOf( '/print' ) > -1 ) {
        this.checkPrintPage = true;
      } else {
        this.checkPrintPage = false;
      };
    } else {
      this.checkAdminPage = false;
    }

    this.subscription1 = this.router.events.subscribe( (event: Event) => {
      if( event instanceof NavigationEnd ) {
        // 페이지 전환 시 최 상단으로 자동 스크롤
        if( this.location.path().indexOf('/statistics') > -1 ) {
          if( this.location.path().indexOf('/statisticsGuide') > -1 ) {
            this.isStatisticsPage = false;
          } else {
            this.isStatisticsPage = true;
          }
        } else {
          this.isStatisticsPage = false;
        }

        if( this.location.path().indexOf('/webzine') > -1 ) {
          this.isWebzinePage = true;
        } else {
          this.isWebzinePage = false;
        }
      }
    });
  }

  ngOnDestroy() {
    console.log('ngOnDestroy');
    this.subscription.unsubscribe();
    this.subscription1.unsubscribe();
  }

}